// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainfooter{
    background-color: white;
    width: 100%;
    border: 4px solid blue;
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
}
.firstfoot h3{
 color: red;
}
.firstfoot p{
    color: blueviolet;
}
.secondfoot{
    background-color: bisque;
    border: 1px solid blue;
    border-radius: 10px;
    width: fit-content;
    margin: auto;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.secondfoot h3:first-child{
    color: rgb(243, 17, 17);
}
.secondfoot h3:nth-child(2){
    color: rgb(65, 59, 59);
}
.secondfoot h3:nth-child(4){
    color: rgb(62, 58, 58);
}
.secondfoot button{
    background-color: brown;
    color: white;
}
.flexitems{
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    color: rgb(98, 62, 62);
}
.thirdfoot h4{
color: darkblue;
}
.thirdfoot h5:hover{
    color:rgb(255, 0, 123);
    letter-spacing: 1px;
}
.fourthfoot{
    font-size: x-large;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/MyFooter/MyFooter.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;CACC,UAAU;AACX;AACA;IACI,iBAAiB;AACrB;AACA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;AACA,eAAe;AACf;AACA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".mainfooter{\n    background-color: white;\n    width: 100%;\n    border: 4px solid blue;\n    border-radius: 10px;\n    margin-top: 10px;\n    text-align: center;\n}\n.firstfoot h3{\n color: red;\n}\n.firstfoot p{\n    color: blueviolet;\n}\n.secondfoot{\n    background-color: bisque;\n    border: 1px solid blue;\n    border-radius: 10px;\n    width: fit-content;\n    margin: auto;\n    padding: 5px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n.secondfoot h3:first-child{\n    color: rgb(243, 17, 17);\n}\n.secondfoot h3:nth-child(2){\n    color: rgb(65, 59, 59);\n}\n.secondfoot h3:nth-child(4){\n    color: rgb(62, 58, 58);\n}\n.secondfoot button{\n    background-color: brown;\n    color: white;\n}\n.flexitems{\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    align-items: center;\n    color: rgb(98, 62, 62);\n}\n.thirdfoot h4{\ncolor: darkblue;\n}\n.thirdfoot h5:hover{\n    color:rgb(255, 0, 123);\n    letter-spacing: 1px;\n}\n.fourthfoot{\n    font-size: x-large;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
