import React from 'react'
import '../Live Matka Result/LiveMatkaResult.css'
import RajaRaniMorning from '../../pages/Raja Rani Morning/RajaRaniMorning'


const LiveMatkaResult = () => {

  
    return (
        <>
            <div className="live-result">
                <div className="main-result">
                    <div className="update-result">
                        <div className="liveupdate">
                            <h2> WORLD ME SABSE FAST SATTA MATKA RESULT </h2>
                        </div>
                    </div>
                </div>
                <RajaRaniMorning />
            </div>


        </>
    )
}

export default LiveMatkaResult
