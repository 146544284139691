import React from "react";
import "./Table.css";

const MyTable = () => {
    const tableData1 = [
        {
            day: "सोम",
            data: [1, [236, 13], 3, [157, 31], 5, [500, 58], 8, [189, 85]],
        },
        {
            day: "मंगल",
            data: [5, [348, 57], 7, [368, 75], 8, [233, 89], 9, [478, 98]],
        },
        {
            day: "बुध",
            data: [4, [220, 47], 7, [557, 74], 8, [477, 89], 9, [469, 98]],
        },
        {
            day: "गुरु",
            data: [5, [113, 56], 6, [556, 65], 8, [233, 89], 9, [234, 98]],
        },
        {
            day: "शुक्र",
            data: [1, [669, 17], 7, [188, 71], 8, [170, 89], 9, [478, 98]],
        },
        {
            day: "शनि",
            data: [3, [779, 36], 6, [150, 63], 8, [115, 78], 7, [477, 87]],
        },
    ];
    const tableData2 = [
        {
            day: "सोम",
            data: [1, [236, 13], 3, [157, 31], 5, [500, 58], 8, [189, 85]],
        },
        {
            day: "मंगल",
            data: [5, [348, 57], 7, [368, 75], 8, [233, 89], 9, [478, 98]],
        },
        {
            day: "बुध",
            data: [4, [220, 47], 7, [557, 74], 8, [477, 89], 9, [469, 98]],
        },
        {
            day: "गुरु",
            data: [5, [113, 56], 6, [556, 65], 8, [233, 89], 9, [234, 98]],
        },
        {
            day: "शुक्र",
            data: [1, [669, 17], 7, [188, 71], 8, [170, 89], 9, [478, 98]],
        },

    ];

    return (
        <div className="table-container">
            {/* <table className="custom-table">
                <caption className='caption2'>कल्याण</caption>
                <tbody>
                    {tableData1.map((row, index) => (
                        <tr key={index}>
                            <th className="weekcolor">{row.day}</th>
                            {row.data.map((cell, i) => (
                                <td key={i} className={Array.isArray(cell) ? "flex-column " : " "} style={{color:"red",fontSize:'large',fontWeight:'bold'}}>
                                    {Array.isArray(cell)
                                        ? cell.map((item, j) => <div style={{color:'black'}} key={j} >{item} {j == 0 ? <hr className="hrstyle" /> : ''} </div>)
                                        : cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table> */}

            {/* <table className="custom-table" >
                <caption className='caption2'>KALYAN NIGHT / MAIN BAZAR</caption>
                <tbody >
                    {tableData2.map((row, index) => (
                        <tr key={index}>
                            <th className="weekcolor">{row.day}</th>
                            {row.data.map((cell, i) => (
                                <td key={i} className={Array.isArray(cell) ? "flex-column" : ""} style={{color:"red",fontSize:'large',fontWeight:'bold'}}>
                                    {Array.isArray(cell)
                                        ? cell.map((item, j) => <div style={{color:'black'}} key={j} >{item} {j == 0 ? <hr className="hrstyle" /> : ''} </div>)
                                        : cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </div>
    );
};

export default MyTable;
