// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom {
    background: linear-gradient(90deg, #fbff9d 0%, #f9f9f6 44%, #beffb4 100%);
  }
  
  .matka-container {
    text-align: center;
    margin: 20px;
    padding: 20px;
  }
  
  .matka-nav {
    margin-bottom: 20px;
    
  }
  
  .matka-nav a {
    font-size: 22px;
    text-decoration: none;
    margin: 0 10px;
    font-weight: 700;
    text-shadow: 1px 1px 2px #FFD700;
  
  }
  
  .matka-nav a:hover {
    text-decoration: underline;
  }
  
  .matka-content p {
    margin: 5px 0;
    font-size:18px ;
    color: #000000;
    text-shadow: 1px 1px 2px #FFD700;
    font-style: italic;
    font-weight: 700;
  
  }
  
  
  
  .matka-phone {
    color: blue !important;
    font-size: 32px !important;
    font-weight: 700 !important;
    text-shadow: 1px 1px 2px #FFD700 !important; 
    font-style: italic !important;
  }
  
  .matka-link {
    color: #000000 !important;
    text-decoration: none !important;
    text-shadow: 1px 1px 2px #FFD700 !important;  
    font-size: 17px !important;
    font-weight: 700 !important ;
  
  }
  
  .matka-link:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yEAAyE;EAC3E;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,mBAAmB;;EAErB;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,gBAAgB;IAChB,gCAAgC;;EAElC;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,eAAe;IACf,cAAc;IACd,gCAAgC;IAChC,kBAAkB;IAClB,gBAAgB;;EAElB;;;;EAIA;IACE,sBAAsB;IACtB,0BAA0B;IAC1B,2BAA2B;IAC3B,2CAA2C;IAC3C,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;IACzB,gCAAgC;IAChC,2CAA2C;IAC3C,0BAA0B;IAC1B,4BAA4B;;EAE9B;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".bottom {\n    background: linear-gradient(90deg, #fbff9d 0%, #f9f9f6 44%, #beffb4 100%);\n  }\n  \n  .matka-container {\n    text-align: center;\n    margin: 20px;\n    padding: 20px;\n  }\n  \n  .matka-nav {\n    margin-bottom: 20px;\n    \n  }\n  \n  .matka-nav a {\n    font-size: 22px;\n    text-decoration: none;\n    margin: 0 10px;\n    font-weight: 700;\n    text-shadow: 1px 1px 2px #FFD700;\n  \n  }\n  \n  .matka-nav a:hover {\n    text-decoration: underline;\n  }\n  \n  .matka-content p {\n    margin: 5px 0;\n    font-size:18px ;\n    color: #000000;\n    text-shadow: 1px 1px 2px #FFD700;\n    font-style: italic;\n    font-weight: 700;\n  \n  }\n  \n  \n  \n  .matka-phone {\n    color: blue !important;\n    font-size: 32px !important;\n    font-weight: 700 !important;\n    text-shadow: 1px 1px 2px #FFD700 !important; \n    font-style: italic !important;\n  }\n  \n  .matka-link {\n    color: #000000 !important;\n    text-decoration: none !important;\n    text-shadow: 1px 1px 2px #FFD700 !important;  \n    font-size: 17px !important;\n    font-weight: 700 !important ;\n  \n  }\n  \n  .matka-link:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
