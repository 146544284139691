import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getmarketData } from "../../server/api"; 
import "./ChartRecords.css";

function ChartRecords() {
  const [marketData, setMarketData] = useState([]);
  const navigate = useNavigate();

  // Fetch market data on component mount
  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await getmarketData();
        if (response && response.data && Array.isArray(response.data)) {
          setMarketData(response.data);
        }
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };

    fetchMarketData();
  }, []);

  function handleJodi(id ) {
    navigate(`/jodi/${id}`)
  }

  function handlePanel(id) {
    navigate(`/panel/${id}`);
  }

  return (
    <>
      <div className="main-jodi-chart">
        <div className="heading">
          <h2>⇛ SATTA MATKA JODI CHART</h2>
        </div>
        {marketData.map((market) => (
          <h2
            key={market._id}
            onClick={() => handleJodi(market._id)}
          >
            {market.name}
          </h2>
        ))}
      </div>

      <div className="main-jodi-chart">
        <div className="heading">
          <h2>SATTA MATKA PANEL CHART</h2>
        </div>
        {marketData.map((market) => (
          <h2
            key={market._id}
            onClick={() => handlePanel(market._id)}
          >
            {market.name}
          </h2>
        ))}
      </div>
    </>
  );
}

export default ChartRecords;
