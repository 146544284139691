import React from 'react'
import './Tabledata.css'

const Tabledata = () => {
    return (
        <div>

            {/* <div className="emailbutton">
                <h3>Email for any inquiries Or Support:
                </h3>
                <button>sattacoolwebsite@gmail.com</button>
            </div> */}

            {/* <div className="loginbtns">
                <button>LOGIN</button>
                <button>Post Your Guessing Here</button>
                <button>REGISTER</button>
            </div> */}

            {/* <div className='maindiv'> */}
                {/* <div id="tabledata"> */}

                    {/* <table className="center">
                        <caption className='caption'>!! NEW BOMBAY STAR LINE RESULT !!</caption>
                        <tr>
                            <th>Time</th>
                            <th>Result</th>
                            <th>Time</th>
                            <th>Result</th>
                        </tr>

                        <tr>
                            <td>10.00AM</td>
                            <td>***_*</td>
                            <td>10.00AM</td>
                            <td>***_*</td>
                        </tr>
                        <tr>
                            <td>10.00AM</td>
                            <td>***_*</td>
                            <td>10.00AM</td>
                            <td>***_*</td>
                        </tr>
                        <tr>
                            <td>10.00AM</td>
                            <td>***_*</td>
                            <td>10.00AM</td>
                            <td>***_*</td>
                        </tr>
                        <tr>
                            <td>10.00AM</td>
                            <td>***_*</td>
                            <td>10.00AM</td>
                            <td>***_*</td>
                        </tr>

                    </table> */}
                {/* </div> */}
                {/* <button className="lastdiv"><i>NEW BOMBAY STAR LINE Panel Chart</i>
                </button> */}
            {/* </div> */}

        // </div>
    )
}

export default Tabledata
