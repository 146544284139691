// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid .main-cont {
    width: 100%;
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 12px;
    margin-top: 12px;
    border-radius: 30px;
    border: 4px solid #F97300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container-fluid .main-cont h1{
    font-size: 30px !important;
    color: rgb(154, 68, 51);
    padding-top: 10px;
  }
  .container-fluid .main-cont p{
    font-size: 20px;
    font-weight: bold;
  }
.Banner{
  border-radius: 20px;
  width: 100%;
  height: 200px;

  overflow: hidden;
}

  .container-fluid .main-cont h3{
    color: blue;
    
  }
  
  .main-cont h1:hover
  {
    color: rgb(5, 10, 255) !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  
 
  
  @media (max-width: 480px) {
    .main-cont {
      font-size: 16px;
    }
    .main-cont h3{
      width: 80%;
      text-align: center;
    }
    
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,0BAA0B;IAC1B,uBAAuB;IACvB,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;;EAEb,gBAAgB;AAClB;;EAEE;IACE,WAAW;;EAEb;;EAEA;;IAEE,iCAAiC;IACjC,6EAA6E;EAC/E;;;;;EAKA;IACE;MACE,eAAe;IACjB;IACA;MACE,UAAU;MACV,kBAAkB;IACpB;;EAEF","sourcesContent":[".container-fluid .main-cont {\n    width: 100%;\n    background-color: #fff;\n    height: 100%;\n    border-radius: 10px;\n    margin-bottom: 12px;\n    margin-top: 12px;\n    border-radius: 30px;\n    border: 4px solid #F97300;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .container-fluid .main-cont h1{\n    font-size: 30px !important;\n    color: rgb(154, 68, 51);\n    padding-top: 10px;\n  }\n  .container-fluid .main-cont p{\n    font-size: 20px;\n    font-weight: bold;\n  }\n.Banner{\n  border-radius: 20px;\n  width: 100%;\n  height: 200px;\n\n  overflow: hidden;\n}\n\n  .container-fluid .main-cont h3{\n    color: blue;\n    \n  }\n  \n  .main-cont h1:hover\n  {\n    color: rgb(5, 10, 255) !important;\n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n  }\n\n  \n \n  \n  @media (max-width: 480px) {\n    .main-cont {\n      font-size: 16px;\n    }\n    .main-cont h3{\n      width: 80%;\n      text-align: center;\n    }\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
