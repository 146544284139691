// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-result{
    border-radius: 30px 30px 0 0 ;
    border: 4px solid #32012F;
    background-color: #FFEAF0;
    margin-top: 12px;
}

.main-result {
   
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.update-result{
    background-color: blue;
    width: 100%;
    border-radius: 26px 26px 0px 0px;
}
.liveupdate{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 10px;
    color: white ;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .liveupdate span {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Live Matka Result/LiveMatkaResult.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;AAEvB;AACA;IACI,sBAAsB;IACtB,WAAW;IACX,gCAAgC;AACpC;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".live-result{\n    border-radius: 30px 30px 0 0 ;\n    border: 4px solid #32012F;\n    background-color: #FFEAF0;\n    margin-top: 12px;\n}\n\n.main-result {\n   \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    \n}\n.update-result{\n    background-color: blue;\n    width: 100%;\n    border-radius: 26px 26px 0px 0px;\n}\n.liveupdate{\n    display: flex;\n    justify-content: center; \n    align-items: center;\n    padding: 10px;\n    color: white ;\n    text-align: center;\n}\n\n@media only screen and (max-width: 768px) {\n    .liveupdate span {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
