// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .table-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
  
  .date-column {
    width: 100px;
    font-weight: bold;
    margin-right: 10px;
  }
  td>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    
}
.red{
  color: red;
  
}
.black{
  color: black;

}
.Wet{
  font-size: 20px;
  font-weight: 500;
}
  .values-column {
    display: flex;
    flex-wrap: wrap;
  }
  
  .value-item {
    width: 30px;
    text-align: center;
    margin-right: 5px;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .value-item.highlight {
    color: red;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/OnlineMatkaPlay/Panel/Panel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;IAC9B,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;;AAEhB;AACA;EACE,UAAU;;AAEZ;AACA;EACE,YAAY;;AAEd;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;EACE;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB","sourcesContent":[".table-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    font-family: Arial, sans-serif;\n    margin: 20px;\n  }\n  \n  .table-row {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 5px;\n  }\n  \n  .date-column {\n    width: 100px;\n    font-weight: bold;\n    margin-right: 10px;\n  }\n  td>div{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 5px;\n    \n}\n.red{\n  color: red;\n  \n}\n.black{\n  color: black;\n\n}\n.Wet{\n  font-size: 20px;\n  font-weight: 500;\n}\n  .values-column {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .value-item {\n    width: 30px;\n    text-align: center;\n    margin-right: 5px;\n    padding: 2px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  .value-item.highlight {\n    color: red;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
