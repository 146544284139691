import React from 'react'

export const RefreshButton = () => {
    function handlereload(){
        window.location.reload();
    }
  return (
    <div>
      <button onClick={handlereload}>REFRESH</button>
    </div>
  )
}


