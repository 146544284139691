// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-jodi-chart{
    border: 3px solid #ffb341;
    border-radius: 6px;
    margin-top: 12px;
    background: linear-gradient(90deg, #FBFF9D 0%, #F9F9F6 44%, #BEFFB4 100%);
    text-align: center;


}
.main-jodi-chart > h2{
        border-bottom: 2px solid #3F95C9;
        color: #0000FF;
        text-shadow: 1px 1px 2px #FF0000;
        padding: 12px;
        margin: 0px;    
        cursor: pointer;
        font-size: larger;
}
.main-jodi-chart > h2:hover{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.heading h2{
    background-color: #FFFFFF;
    text-align: center;
    margin: 0px;
    padding: 12px;
    color: #f72020;
    text-shadow: 1px 2px 2px #000;
    border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Chart Records/ChartRecords.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,yEAAyE;IACzE,kBAAkB;;;AAGtB;AACA;QACQ,gCAAgC;QAChC,cAAc;QACd,gCAAgC;QAChC,aAAa;QACb,WAAW;QACX,eAAe;QACf,iBAAiB;AACzB;AACA;IACI,sHAAsH;AAC1H;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,cAAc;IACd,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".main-jodi-chart{\n    border: 3px solid #ffb341;\n    border-radius: 6px;\n    margin-top: 12px;\n    background: linear-gradient(90deg, #FBFF9D 0%, #F9F9F6 44%, #BEFFB4 100%);\n    text-align: center;\n\n\n}\n.main-jodi-chart > h2{\n        border-bottom: 2px solid #3F95C9;\n        color: #0000FF;\n        text-shadow: 1px 1px 2px #FF0000;\n        padding: 12px;\n        margin: 0px;    \n        cursor: pointer;\n        font-size: larger;\n}\n.main-jodi-chart > h2:hover{\n    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n}\n\n.heading h2{\n    background-color: #FFFFFF;\n    text-align: center;\n    margin: 0px;\n    padding: 12px;\n    color: #f72020;\n    text-shadow: 1px 2px 2px #000;\n    border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
