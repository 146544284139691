import React, { useEffect, useState } from "react";
import "../../assets/liveupdate.css";
import { RefreshButton } from "../../components/Buttons/RefreshButton";
import { getmarketData, getSliderData, results } from "../../server/api";
import baseUrl from "../../server/baseUrl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function LiveUpdate({ data }) {
  const [markets, setMarkets] = useState([]);
  const [error, setError] = useState(null);
  const [imgs, setImgs] = useState(null);
  const [imgData,setImgData] = useState([])
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  const checkTime = (time) => {
    const currentTime = new Date();
    const specifiedTime = new Date();
    const timeh = time.split(":")[0];
    const timem = time.split(":")[1];

    specifiedTime.setHours(Number(timeh), Number(timem), 0, 0); // 13:15

    if (currentTime > specifiedTime) {
      return false;
    } else {
      return true;
      // console.log("firss", timeh, timem);
    }
  };

  const checkFun = (data) => {
    var count = 0;
    var datas = [];
    for (var i = 0; i < data.length; i++) {
      if (checkTime(data[i].close_time) && count < 3) {
        count++;
        datas.push(data[i]);
      }
    }
    setMarkets(datas);
  };
  const fetchMarketData = async () => {
    try {
      const response = await getmarketData();
      if (response && response.data && Array.isArray(response.data)) {
        // setMarkets(response.data);
        checkFun(response.data);
        // Set the entire array of markets
        // console.log(response.data, "jjjj"); // Log the entire data array
      } else {
        console.error("Unexpected data structure:", response.data);
        setError("Unexpected data structure");
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
      setError("Failed to fetch market data");
    }
  };
  const fetchMarketData2 = async () => {
    try {
      const response = await getSliderData();
      if (response) {
        setImgData(response?.data)
        setImgs(response?.data[1]?.link);
      } else {
        console.error("Unexpected data structure:", response.data);
        setError("Unexpected data structure");
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
      setError("Failed to fetch market data");
    }
  };
  useEffect(() => {
    fetchMarketData();
    fetchMarketData2();
  }, []);
  // console.log(data)
  return (
    <>
      <div className="main-Containers">
        <div className="main-contant">
          <div className="udpateback">
            <div className="liveupdate">
              <h2>Today Live Result</h2>
              <span className="blinking">NEW</span>
            </div>
          </div>

          <div className="num-update">
            {error ? (
              <h2>{error}</h2>
            ) : markets.length > 0 ? (
              markets.map((market) => (
                <div key={market._id}>
                  <h2>{market.name}</h2>
                  <p className="center-text">
                    {results(
                      market?.open_panna,
                      market?.open_digit,
                      market?.close_digit,
                      market?.close_panna
                    )}
                  </p>{" "}
                </div>
              ))
            ) : (
              <h2>Loading...</h2>
            )}
            <RefreshButton />
          </div>
        </div>

        <div className="fotter">
          <div className="fotter-itme">
            <h2>[ 卐 बुकिंग चालू | बुकिंग चालू 卐 ]</h2>
            <h2>
              धमाका अचूक जोड़ी पर कमाओ लाखों 100% फिक्स 1 जोड़ी 2 पत्ती सिर्फ एक
              दिन में-पूरा लॉस कबर होगा एडवांस चार्ज 2500/- मात्र
            </h2>
            <p>कॉल / WhatsApp : {data?.whatsapp}</p>
          </div>
        </div>
        <div className="Banner">
          {/* <img src={`${baseUrl}?${ imgs}`} alt="" /> */}
          {/* <img src={`${baseUrl}misc/image?type=slider&name=${imgs}`} /> */}
          <Slider {...settings}>
            {imgData.map((item,index)=>(

          <div key={index} style={{ width: "100%",height:'200px',display:'flex',alignItems:'center',justifyContent:'center' }}>
            <img src={`${baseUrl}misc/image?type=slider&name=${item?.link}`} alt="img" style={{ width: "100%",height:'200px'}}   />
          </div>
            ))}
          
        </Slider>
        </div>
        <div className="matka-about">
          <h2>✨ Add Your Market ✨</h2>
          <p>Add Your Market in Lowest Price for Sai Online Matka</p>
          <button
            onClick={() => window.open(`https://wa.me/${data?.whatsapp}`)}
          >
            Click Here WhatsAPP
          </button>
        </div>
      </div>
    </>
  );
}

export default LiveUpdate;
