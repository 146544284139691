import React from "react";
import "./TopBottomButtons.css"; 
 
export const BottomtoTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="scroll-buttons">
      <button onClick={scrollToTop} className="btn">Go to Top</button>
    </div>
  );
}

export const ToptoBottom = () => {

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", 
    });
  };

  return (
    <div className="scroll-buttons">
      <button onClick={scrollToBottom} className="btn">Go to Bottom</button>
    </div>
  );
};

