import React from 'react'
import './MyFooter.css'

const MyFooter = () => {
    return (
        <>
            <div className="mainfooter">
                <div className="firstfoot">
                    <h3>-: DISCLAIMER :-</h3>
                    <p>This platform serves as a news publishing site. All figures and information presented here are derived from arithmetic calculations and Artificial Intelligence (AI), tailored according to zodiac signs. Our website maintains no affiliation with any gambling institutions and is not involved in any illegal activities such as gambling or money laundering. This site relies solely on Google Ad Revenue. All displayed information is sourced from various internet resources.</p>
                </div>
                {/* <div className="secondfoot">
                    <h3>Visit Sai Online Matka</h3>
                    <h3>© 2014-2024 All Rights Reserved</h3>
                    <h2>Website and App बनवाने और SEO करवाने के लिए सम्पर्क करें:</h2>
                    <h3>Contact Admin</h3>
                    <button>Call- 7847031754</button>
                </div> */}
                <div className="thirdfoot">
                    <h4>© 2010 - 2024 https://saionlinematkaapp.in.net</h4>
                    <div className='flexitems'>
                        <h5>Terms & Conditions</h5>
                        |
                        <h5> Privacy Policy</h5>
                    </div>
                    <div className='flexitems'>
                        <h5>About Us </h5>
                        |
                        <h5>Contact Us</h5>
                    </div>
                    <div className="fourthfoot">
                        POWERD BY Sai Online Matka
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyFooter
