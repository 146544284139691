import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import LiveMatkaResult from './components/Live Matka Result/LiveMatkaResult';
import Jodi from './pages/Jodi/Jodi';
import Panel from './pages/OnlineMatkaPlay/Panel/Panel';
import ChartRecords from './pages/Chart Records/ChartRecords';
import Tabledata from './components/Table/Tabledata';
import MyTable from './components/Table/MyTable';
import MyFooter from './components/MyFooter/MyFooter';

function Home() {
  return (
    <>
      <Header />
      <LiveMatkaResult />
      <Tabledata />
      <MyTable />
      <ChartRecords />
      <MyFooter />
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/jodi/:id" element={<Jodi />} />
          <Route path="/panel/:id" element={<Panel />} />
      
        </Routes>
      </Router>
    </div>
  );
}

export default App;

